import './TransactionChart.scss';

import {
	Budget,
	Category,
	FrequencyOptions,
	TransactionList,
} from '../../redux/Model';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';
import { useEffect, useState } from 'react';
import {
	findCategoryIndex,
	findBudget,
} from '../../redux/reducers/reducerUtils';
import { Button } from 'carbon-components-react';
import { BudgetAppState } from '../../redux/storeModel';
import {
	calcAnnualValue,
	calcFortnightlyValue,
	calcMonthlyValue,
	calcQuarterlyValue,
	calcWeeklyValue,
} from '../../utils/BudgetUtils';
import { connect } from 'react-redux';
import selectBudgetTotals from '../../redux/selectors/selectBudgetTotals';
import selectBudgetItemDefinitions from '../../redux/selectors/selectBudgetItemDefinitions';
import selectTransactionTotals from '../../redux/selectors/selectTransactionTotals';

interface OwnProps {
	transactionList: TransactionList;
	totals: ReturnType<typeof selectTransactionTotals>;
	frequency: FrequencyOptions;
}

interface TransactionChartElement {
	name: string;
	total: number;
	transactionTotal: number;
	id: string;
}

export type BudgetChartProps = OwnProps & ReturnType<typeof mapStateToProps>;

const TransactionChart: React.FC<BudgetChartProps> = (props) => {
	const [isDrilldown, setIsDrilldown] = useState<boolean>(false);
	const [drilldownId, setDrilldownId] = useState<string | null>(null);

	const budgetTotals = selectBudgetTotals(props.budget, props.frequency);

	const data: Array<TransactionChartElement> = [];

	if (!isDrilldown) {
		data.push({
			name: 'Uncategorised',
			total: 0,
			transactionTotal: 0,
			id: 'uncategorised',
		});
	}

	const categories = props.budget.categories;

	useEffect(() => {
		if (isDrilldown && !drilldownId) {
			setIsDrilldown(false);
			setDrilldownId(null);
		}

		if (
			isDrilldown &&
			drilldownId &&
			!budgetTotals.categoryTotals.has(drilldownId)
		) {
			setIsDrilldown(false);
			setDrilldownId(null);
		}
	}, [isDrilldown, drilldownId, budgetTotals.categoryTotals, categories]);

	let category: Category | null = null;

	if (isDrilldown && drilldownId) {
		category =
			props.budget.categories[
				findCategoryIndex(props.budget.categories, drilldownId)
			];

		category.items.forEach((item) => {
			let value = 0;
			switch (props.frequency) {
				case FrequencyOptions.Annually:
				case FrequencyOptions.All:
				case FrequencyOptions.FinancialYear: {
					value = calcAnnualValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Quarterly: {
					value = calcQuarterlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Monthly: {
					value = calcMonthlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Fortnightly: {
					value = calcFortnightlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Weekly: {
					value = calcWeeklyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
			}

			data.push({
				name: item.name,
				total: Number.parseFloat(value.toFixed(2)),
				transactionTotal: 0,
				id: item.UUID,
			});
		});

		props.transactionList.transactions.forEach((transaction) => {
			const item = category?.items.find((item) => {
				return item.UUID === transaction.itemId;
			});

			if (item) {
				let categoryEntry = data.find((entry) => entry.id === item.UUID);

				if (categoryEntry) {
					categoryEntry.transactionTotal +=
						Number.parseFloat(transaction.amount) * -1;

					// Invert income again, as it shouldn't be negative
					if (category?.isIncome && categoryEntry.transactionTotal < 0) {
						categoryEntry.transactionTotal *= -1;
					}
				} else {
					console.warn('Could not find category entry in data for category!');
				}
			}
		});
	} else {
		props.budget.categories.forEach((category) => {
			if (category.isIncome) {
				data.push({
					name: category.name,
					total: Number.parseFloat(
						(budgetTotals.categoryTotals.get(category.UUID) || 0).toFixed(2)
					),
					transactionTotal: 0,
					id: category.UUID,
				});
			}

			if (!category.isIncome) {
				data.push({
					name: category.name,
					total:
						Number.parseFloat(
							(budgetTotals.categoryTotals.get(category.UUID) || 0).toFixed(2)
						) * -1,
					transactionTotal: 0,
					id: category.UUID,
				});
			}
		});

		props.transactionList.transactions.forEach((transaction) => {
			// If no category ID, transaction is uncategorised.
			if (transaction.categoryId === undefined) {
				const transactionAmount = Number.parseFloat(transaction.amount);
				data[0].transactionTotal += transactionAmount * -1;
				return;
			}

			const category = categories.find((category) => {
				return category.UUID === transaction.categoryId;
			});

			if (category) {
				let categoryEntry = data.find((entry) => entry.id === category.UUID);

				if (categoryEntry) {
					categoryEntry.transactionTotal += category.isIncome
						? Number.parseFloat(transaction.amount)
						: Number.parseFloat(transaction.amount) * -1;
				} else {
					console.warn('Could not find category entry in data for category!');
					data[0].transactionTotal +=
						Number.parseFloat(transaction.amount) * -1;
				}
			} else {
				console.warn('Category not found!');
				data[0].transactionTotal += Number.parseFloat(transaction.amount) * -1;
			}
		});
	}

	return (
		<div className="transaction-chart-container">
			<div className="transaction-chart-headers-container">
				<h2 className="transaction-chart-title">
					Expenses {isDrilldown && category !== null && ` - ${category.name}`}
				</h2>
				{isDrilldown && (
					<Button
						kind="primary"
						onClick={() => {
							setIsDrilldown(false);
							setDrilldownId(null);
						}}
					>
						Back
					</Button>
				)}
			</div>
			<ResponsiveContainer width="100%" height={500}>
				<BarChart width={500} height={300} data={data}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					{/* <Legend /> */}
					<Bar
						dataKey="total"
						fill="#8884d8"
						onClick={(evt) => {
							if (isDrilldown || evt.id === data[0].id) {
								return;
							}
							setIsDrilldown(true);
							setDrilldownId(evt.id);
						}}
					/>
					<Bar
						dataKey="transactionTotal"
						fill="#82ca9d"
						onClick={(evt) => {
							if (isDrilldown || evt.id === data[0].id) {
								return;
							}
							setIsDrilldown(true);
							setDrilldownId(evt.id);
						}}
					/>
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

const mapStateToProps = (state: BudgetAppState, ownProps: OwnProps) => {
	/**
	 * Type asserted as we will enforce a transaction must be associated with
	 * a budget that is loaded into memory.
	 */
	const budget = findBudget(
		state.budgets,
		ownProps.transactionList.budgetId
	) as Budget;

	return {
		budget,
		categoryMap: selectBudgetItemDefinitions(budget),
	};
};

export default connect(mapStateToProps)(TransactionChart);
