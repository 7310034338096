import { DateTime } from 'luxon';
import { savedFrequencyToLoaded } from '../../../utils/BudgetUtils';
import {
	Budget,
	Category,
	FrequencyOptions,
	TransactionList,
} from '../../Model';
import { BudgetAppState } from '../../storeModel';
import { LoadBudgetAction } from './action';

export const reduceLoadBudget = (
	prevState: BudgetAppState,
	action: LoadBudgetAction
): BudgetAppState => {
	const newBudgetList = [...prevState.budgets];

	// Convert the budget data from the serialised form to what is expected in redux and add it to the new list.
	const categories: Array<Category> = action.budget.budget.categories.map(
		(savedCategory) => ({
			...savedCategory,
			items: savedCategory.items.map((item) => ({
				...item,
				frequency:
					typeof item.frequency === 'number'
						? savedFrequencyToLoaded(item.frequency)
						: (item.frequency as FrequencyOptions),
			})),
		})
	);
	const newBudget: Budget = {
		...action.budget.budget,
		fromDB: action.fromDB,
		categories,
	};
	newBudgetList.push(newBudget);

	// Convert the serialised transaction list to what is expected in redux.
	const newTransactionLists: Array<TransactionList> =
		action.budget.transactionLists.map((transactionList) => ({
			...transactionList,
			transactions: transactionList.transactions.map((transaction) => ({
				...transaction,
				date: DateTime.fromISO(transaction.date),
			})),
		}));

	return {
		...prevState,
		budgets: newBudgetList,
		// Add the new list
		transactionLists: [...prevState.transactionLists].concat(
			newTransactionLists
		),
	};
};
