import { AddAlt24, TrashCan24 } from '@carbon/icons-react';
import { AnyAction } from '@reduxjs/toolkit';
import {
	Button,
	Checkbox,
	Select,
	SelectItem,
	// Tile
} from 'carbon-components-react';
import { NumberInput, TextInput } from 'carbon-components-react';
import React, { Dispatch, ReactNode } from 'react';
import { connect } from 'react-redux';
import { BudgetItem, FrequencyOptions } from '../../../redux/Model';
import { addBudgetItem } from '../../../redux/reducers/addBudgetItem/action';
import { deleteBudgetItem } from '../../../redux/reducers/deleteBudgetItem/action';
import { updateBudgetItem } from '../../../redux/reducers/updateBudgetItem/action';
import './MobileBudgetItemTable.scss';

const headers = [
	{
		key: 'name',
		header: 'Item Name',
	},
	{
		key: 'value',
		header: '$',
	},
	{
		key: 'frequency',
		header: 'Frequency',
	},
];

interface BudgetItemHeader {
	key: string;
	header: string;
}

interface OwnProps {
	budgetId: string;
	categoryId: string;
	categoryTotal?: number;
	items: Array<BudgetItem>;
	budgetFrequency: string;
}

export type BudgetItemTableProps = OwnProps &
	ReturnType<typeof mapDispatchToProps>;

const renderBudgetInput = (
	itemId: string,
	cellId: string,
	cellValue: string | number,
	updateState: (
		itemId: string,
		cellId: string,
		cellValue: string | number | boolean | FrequencyOptions
	) => void
): ReactNode => {
	if (cellId.includes('frequency')) {
		return (
			<Select
				id={`${cellId}-input`}
				value={cellValue}
				labelText=""
				onChange={(event) => {
					updateState(itemId, cellId, event.target.value);
				}}
				key={cellId}
			>
				{Object.values(FrequencyOptions).map((val) => {
					return <SelectItem text={val} value={val} key={val}></SelectItem>;
				})}
			</Select>
		);
	}

	const updateNumberState = (event: any, change: any) => {
		if (change.value !== undefined && isNaN(Number.parseFloat(change.value))) {
			updateState(itemId, cellId, 0);
			return;
		}

		if (change.value !== undefined) {
			updateState(itemId, cellId, Number.parseFloat(change.value));
		} else {
			updateState(
				itemId,
				cellId,
				change === 'up' ? (cellValue as number) + 1 : (cellValue as number) - 1
			);
		}
	};

	switch (typeof cellValue) {
		case 'string': {
			return (
				<TextInput
					id={`${itemId}-${cellId}-input`}
					value={cellValue}
					labelText=""
					key={cellId}
					onChange={(event) => {
						updateState(itemId, cellId, event.target.value);
					}}
				/>
			);
		}
		case 'number': {
			return (
				<NumberInput
					id={`${itemId}-${cellId}-input`}
					value={cellValue}
					key={cellId}
					onChange={updateNumberState as any}
				/>
			);
		}
		case 'boolean': {
			return (
				<Checkbox
					id={`${itemId}-${cellId}-input`}
					aria-label="Is an income item"
					labelText=""
					checked={cellValue}
					key={cellId}
					onChange={(value, id, event) => {
						updateState(itemId, cellId, value);
					}}
				/>
			);
		}
		default: {
			console.error(`Encountered unsupported type: ${typeof cellValue}`);
			return <></>;
		}
	}
};

const BudgetItemTable: React.FC<BudgetItemTableProps> = (props) => {
	const updateState = (
		itemId: string,
		cellId: string,
		cellValue: string | number | boolean | FrequencyOptions
	) => {
		const item = props.items.find((item) => {
			return item.UUID === itemId;
		});

		if (!item) {
			console.error('Item not found!');
			return;
		}

		const newValue = { ...item };
		(newValue as any)[cellId] = cellValue;

		props.dispatchUpdateBudgetItem(props.budgetId, props.categoryId, newValue);
	};

	const renderItemMobile = (header: BudgetItemHeader, item: BudgetItem) => {
		const key = header.key;
		const value = (item as any)[key] as any;

		return (
			<div key={key} className="mobile--budget-item-cell">
				<p className="mobile--budget-item-mobile-header" key={header.key}>
					{header.header}
				</p>
				<div className="mobile--budget-item-input-container">
					{renderBudgetInput(item.UUID, key, value, updateState)}
				</div>
			</div>
		);
	};

	return (
		<div className="mobile--budget-item-table-container">
			{props.items.map((item) => {
				return (
					<div className="mobile--budget-item-row" key={item.UUID}>
						{headers.map((header, index) => {
							return renderItemMobile(header, item);
						})}
						<div className="mobile--budget-item-cell budget-item-btn">
							<Button
								hasIconOnly
								renderIcon={AddAlt24}
								iconDescription="Add Item"
								kind="ghost"
								tooltipPosition="top"
								onClick={() => {
									props.dispatchAddBudgetItem(props.budgetId, props.categoryId);
								}}
							/>
							<Button
								hasIconOnly
								renderIcon={TrashCan24}
								iconDescription="Delete Item"
								kind="ghost"
								tooltipPosition="top"
								onClick={() => {
									props.dispatchDeleteBudgetItem(
										props.budgetId,
										props.categoryId,
										item.UUID
									);
								}}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
	return {
		dispatchUpdateBudgetItem: (
			budgetId: string,
			categoryId: string,
			budgetItem: BudgetItem
		) => {
			dispatch(updateBudgetItem(budgetId, categoryId, budgetItem));
		},
		dispatchAddBudgetItem: (budgetId: string, categoryId: string) => {
			dispatch(addBudgetItem(budgetId, categoryId));
		},
		dispatchDeleteBudgetItem: (
			budgetId: string,
			categoryId: string,
			budgetItemId: string
		) => {
			dispatch(deleteBudgetItem(budgetId, categoryId, budgetItemId));
		},
	};
};

export default connect(null, mapDispatchToProps)(BudgetItemTable);
