import { Budget, FrequencyOptions } from '../redux/Model';

const budgetLabels = ['General Individual'];

const budgetDescriptions = [
	'A good starter template for personal finance, based on the money smart budget template.',
];

const budgetTemplates: Array<Budget> = [
	{
		UUID: '5a661d55-3329-4930-b016-9632d28e7701',
		name: 'Personal Budget',
		categories: [
			{
				UUID: '88f11fd3-6cae-4acf-9c9c-b743729bb9be',
				name: 'Income',
				total: 0,
				isIncome: true,
				items: [
					{
						UUID: 'a2276d2e-efca-40aa-b253-cbd2f7bb85bb',
						name: 'Take Home Pay',
						value: 0,
						frequency: FrequencyOptions.Monthly,
					},
				],
			},
			{
				UUID: 'a9213cc0-c7cf-4d14-a711-db008d2c59f6',
				name: 'Home & Utilities',
				total: 0,
				isIncome: false,
				items: [
					{
						UUID: '041fb289-6ede-4e2a-b40a-a89e6702170f',
						name: 'Council Rates',
						value: 0,
						frequency: FrequencyOptions.Annually,
					},
					{
						UUID: 'b036f7bf-24ac-4454-85da-4776cbbcd481',
						name: 'Electricity',
						value: 0,
						frequency: FrequencyOptions.Monthly,
					},
					{
						UUID: 'f4bc23d9-88e4-42c4-9707-998ed3f32016',
						name: 'Gas',
						value: 0,
						frequency: FrequencyOptions.Monthly,
					},
					{
						UUID: 'a277c4bf-f1a8-4701-a3fe-5d25d90a0e33',
						name: 'Water',
						value: 0,
						frequency: FrequencyOptions.Quarterly,
					},
					{
						UUID: '801a8eb1-2e58-43ca-b85e-ab0a27d1a131',
						name: 'Internet',
						value: 0,
						frequency: FrequencyOptions.Monthly,
					},
					{
						UUID: '16b9f857-c7ee-480a-8ff4-72fb56f85043',
						name: 'Mobile',
						value: 0,
						frequency: FrequencyOptions.Monthly,
					},
				],
			},
			{
				UUID: '9f730f62-b490-4de3-8bab-975afe5265fa',
				name: 'Insurance & Financial',
				total: 0,
				isIncome: false,
				items: [
					{
						UUID: 'b9ec475f-bda6-4406-b7c0-ff1d062044d5',
						name: 'Car Insurance',
						value: 0,
						frequency: FrequencyOptions.Annually,
					},
					{
						UUID: '596802d2-4a01-4585-a05c-798dc195dec9',
						name: 'Home & Contents Insurance',
						value: 0,
						frequency: FrequencyOptions.Annually,
					},
					{
						UUID: '65943c7f-dc1a-44fa-8bb3-4b3564c55fb0',
						name: 'Paying off debt',
						value: 0,
						frequency: FrequencyOptions.Monthly,
					},
				],
			},
			{
				UUID: '59a24299-83bf-4404-8dd4-9f25f8bc9c24',
				name: 'Groceries',
				total: 0,
				isIncome: false,
				items: [
					{
						UUID: '2fe31ef6-94fb-4768-bf7d-5306bfa1e69f',
						name: 'Supermarket',
						value: 0,
						frequency: FrequencyOptions.Weekly,
					},
				],
			},
			{
				UUID: '4c959828-bbaf-409b-a25a-1a54f109b173',
				name: 'Personal & Medical',
				total: 0,
				isIncome: false,
				items: [
					{
						UUID: '4ed20262-3960-4bff-a3fe-2cc782ada23f',
						name: 'Cosmetics & Toiletries',
						value: 0,
						frequency: FrequencyOptions.Monthly,
					},
					{
						UUID: '2ae01736-1710-4853-9922-2ed70e9d2e28',
						name: 'Hair & Beauty',
						value: 0,
						frequency: FrequencyOptions.Monthly,
					},
					{
						UUID: '500bd666-3c7c-4875-ae17-03495c45f623',
						name: 'Medicines & Pharmacy',
						value: 0,
						frequency: FrequencyOptions.Monthly,
					},
					{
						UUID: 'd0d26bee-6089-40f7-982c-a760ec13e73c',
						name: 'Doctors & Medical',
						value: 0,
						frequency: FrequencyOptions.Annually,
					},
					{
						UUID: '60ef1b25-21ad-40cf-bcca-1ba1b57f1d13',
						name: 'Clothing & Shoes',
						value: 0,
						frequency: FrequencyOptions.Quarterly,
					},
				],
			},
			{
				UUID: 'a5d742dc-e61d-40ee-82a5-f4fae071ab95',
				name: 'Entertainment & Eating-out',
				total: 0,
				isIncome: false,
				items: [
					{
						UUID: '21e8b0f6-89da-4961-a167-ddb8f78a8eae',
						name: 'Movies & Music',
						value: 0,
						frequency: FrequencyOptions.Monthly,
					},
				],
			},
			{
				UUID: 'c9c92588-0418-47aa-b542-3c087a0f2a28',
				name: 'Transport & Auto',
				total: 0,
				isIncome: false,
				items: [
					{
						UUID: 'ad5a33bc-58a5-43ed-a90c-a7f41cb61050',
						name: 'Bus/Train/Ferry',
						value: 0,
						frequency: FrequencyOptions.Weekly,
					},
					{
						UUID: 'ca939477-9905-487b-a91f-1c9593bbc7ac',
						name: 'Petrol',
						value: 0,
						frequency: FrequencyOptions.Fortnightly,
					},
					{
						UUID: '5592907b-1396-464d-8706-f03fa520b674',
						name: 'Rego & Licence',
						value: 0,
						frequency: FrequencyOptions.Annually,
					},
					{
						UUID: '845e63f5-dfcf-4bce-8646-f1bd89ec91e3',
						name: 'Repairs & Maintenance',
						value: 0,
						frequency: FrequencyOptions.Annually,
					},
				],
			},
			{
				UUID: '8cf3803b-ba47-4e63-9983-f4408fe6854f',
				name: 'Children',
				total: 0,
				isIncome: false,
				items: [
					{
						UUID: 'aecdee21-12c1-45e9-a058-aa5ac7ccb43d',
						name: '',
						value: 0,
						frequency: FrequencyOptions.Annually,
					},
				],
			},
		],
	},
];

const templates = {
	budgetLabels,
	budgetDescriptions,
	budgetTemplates,
};

export default templates;
