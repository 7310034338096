import {
	calcAnnualValue,
	calcQuarterlyValue,
	calcMonthlyValue,
	calcFortnightlyValue,
	calcWeeklyValue,
} from '../../utils/BudgetUtils';
import { FrequencyOptions, BudgetTotals, Budget } from '../Model';

const selectBudgetTotals = (
	budget: Budget,
	frequency: FrequencyOptions
): BudgetTotals => {
	if (budget === undefined) {
		throw new Error('Budget not found!');
	}

	let total = 0;
	let incomeTotal = 0;
	let expenseTotal = 0;

	const categoryTotals = new Map<string, number>();

	budget.categories.forEach((category) => {
		let categoryTotal = 0;

		category.items.forEach((item) => {
			switch (frequency) {
				case FrequencyOptions.FinancialYear:
				case FrequencyOptions.Annually: {
					const value = calcAnnualValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					if (category.isIncome) {
						categoryTotal += value;
						incomeTotal += value;
					} else {
						categoryTotal -= value;
						expenseTotal += value;
					}
					break;
				}
				case FrequencyOptions.Quarterly: {
					const value = calcQuarterlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					if (category.isIncome) {
						categoryTotal += value;
						incomeTotal += value;
					} else {
						categoryTotal -= value;
						expenseTotal += value;
					}
					break;
				}
				case FrequencyOptions.Monthly: {
					const value = calcMonthlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					if (category.isIncome) {
						categoryTotal += value;
						incomeTotal += value;
					} else {
						categoryTotal -= value;
						expenseTotal += value;
					}
					break;
				}
				case FrequencyOptions.Fortnightly: {
					const value = calcFortnightlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					if (category.isIncome) {
						categoryTotal += value;
						incomeTotal += value;
					} else {
						categoryTotal -= value;
						expenseTotal += value;
					}
					break;
				}
				case FrequencyOptions.Weekly: {
					const value = calcWeeklyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					if (category.isIncome) {
						categoryTotal += value;
						incomeTotal += value;
					} else {
						categoryTotal -= value;
						expenseTotal += value;
					}
					break;
				}
			}
		});

		categoryTotals.set(category.UUID, categoryTotal);
	});

	categoryTotals.forEach((categoryTotal) => {
		total += categoryTotal;
	});

	const surplus = incomeTotal - expenseTotal;

	return {
		total,
		incomeTotal,
		expenseTotal,
		surplus,
		categoryTotals,
	};
};

export default selectBudgetTotals;
